<script setup>
import premadeModels from '@/assets/data/premadeBasicInfo.js'
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, ref, watch, toRefs } from 'vue'
import { useStore } from 'vuex'
import AppHeader from '@/components/AppHeader.vue'
import { useHead } from '@unhead/vue'
import { useMixpanel } from '@/composables/mixpanel'
import { usePaymentNotifier } from '@/composables/usePaymentNotifier'
import Banner from './components/Banner.vue'
import InfoCard from './components/InfoCard.vue'
import FooterComponent from '@/components/Footer.vue'
import FAQCard from './components/FAQCard.vue'
import ModelCard from '@/components/ModelCard.vue'
import GenerateModelCard from './components/GenerateModelCard.vue'
import SvgIcon from '@/components/SvgIcon.vue'

const props = defineProps({
  route: String,
  meta: Object,
  content: Object,
})

const { route, meta, content } = toRefs(props)

const $route = useRoute()

const gender = ref($route.meta.homePageRoute == 'boyfriend' ? 'male' : 'female')

useHead(
  computed(() => ({
    title: meta.value.title,
    meta: [
      { name: 'description', content: meta.value.description },

      { property: 'og:title', content: meta.value.title },
      { property: 'og:description', content: meta.value.description },
      {
        property: 'og:url',
        content: `https://${import.meta.env.VITE_DOMAIN_NAME}${route.value}`,
      },
      {
        property: 'og:image',
        content: `https://${import.meta.env.VITE_DOMAIN_NAME}/twitter-card.png`,
      },
      {
        property: 'og:logo',
        content: `https://${import.meta.env.VITE_DOMAIN_NAME}/logo.png`,
      },

      { name: 'twitter:title', content: meta.value.title },
      { name: 'twitter:description', content: meta.value.description },
      {
        name: 'twitter:url',
        content: `https://${import.meta.env.VITE_DOMAIN_NAME}${route.value}`,
      },
      {
        name: 'twitter:image',
        content: `https://${import.meta.env.VITE_DOMAIN_NAME}/twitter-card.png`,
      },
      {
        name: 'twitter:site',
        content: `@${import.meta.env.VITE_TWITTER_HANDLE}`,
      },
    ],
    ...(meta.value.breadcrumbName && {
      script: [
        {
          type: 'application/ld+json',
          innerHTML: `{ "@context": "https://schema.org", "@type": "BreadcrumbList", "itemListElement": [{ "@type": "ListItem", "position": 1, "name": "${meta.value.breadcrumbName}", "item": "https://${import.meta.env.VITE_DOMAIN_NAME}${route.value}" }] }`,
        },
      ],
    }),
  })),
)

const $store = useStore()

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const shownPremadeModels = computed(() =>
  premadeModels
    .filter((el) => !el.hidden)
    .filter((el) => {
      if ($route.meta.homePageRoute == 'girlfriend') {
        return el.model.style == 'realistic' && el.model.gender == 'female'
      }

      if ($route.meta.homePageRoute == 'boyfriend') {
        return el.model.style == 'realistic' && el.model.gender == 'male'
      }

      if ($route.meta.homePageRoute == 'anime') {
        return el.model.style == 'anime' && el.model.gender == gender.value
      }
    }),
)

if (window.location.pathname === '//') {
  window.location.pathname = '/'
}

$store.commit('UPDATE_LAST_APP_PAGE')

const mixpanel = useMixpanel()

usePaymentNotifier()
mixpanel.track('explore_page_visit', {}, { source: 'previous' })

import('@/views/ModelDetails.vue')
import('@/views/CreateModel/CreateModel.vue')
</script>

<template>
  <div class="lg:ml-[280px] bg-[#070917]">
    <AppHeader />
    <div
      class="px-4 xl:px-16 3xl:mx-auto m-auto max-w-[1450px]"
      :class="discountPopupOpen ? 'mt-[90px] lg:mt-[64px]' : ''"
    >
      <div
        class="p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] mb-3 lg:mt-0 flex md:hidden"
      >
        <router-link
          to="/"
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              $route.meta.homePageRoute == 'girlfriend',
          }"
          ><SvgIcon
            sprite="home"
            icon-id="female"
            color="white"
            class="h-[18px] w-[18px]"
            alt="Female icon"
          />
          Girlfriend</router-link
        >
        <router-link
          to="/ai-hentai"
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              $route.meta.homePageRoute == 'anime',
          }"
          ><SvgIcon
            sprite="home"
            icon-id="anime"
            class="h-[18px] w-[18px]"
            alt="Hentai icon"
          />
          Anime</router-link
        >
        <router-link
          to="/ai-boyfriend"
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              $route.meta.homePageRoute == 'boyfriend',
          }"
          ><SvgIcon
            sprite="home"
            icon-id="male"
            class="h-[18px] w-[18px]"
            alt="Male icon"
          />
          Boyfriend</router-link
        >
      </div>
      <Banner
        v-bind="content.banner"
        :cta="content.cta"
        :ctaSm="content.ctaSm"
        :ctaLink="
          !content.genderSwitch
            ? content.ctaLink
            : gender === 'female'
              ? content.genderSwitch.ctaLinkFemale
              : content.genderSwitch.ctaLinkMale
        "
        :image="
          !content.genderSwitch
            ? content.banner.image
            : gender === 'female'
              ? content.genderSwitch.bannerImageFemale
              : content.genderSwitch.bannerImageMale
        "
      />
      <div class="mt-4 lg:mt-6">
        <div
          class="grid gap-2 lg:gap-6 grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
        >
          <div
            v-if="content.genderSwitch"
            class="col-span-full w-full lg:max-w-[480px] cursor-pointer sm:max-w-unset mx-auto p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] flex mb-2 lg:mb-0"
          >
            <button
              class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
              :class="{
                'border border-white border-opacity-15 bg-[#2a3b5f]':
                  gender == 'female',
              }"
              @click="gender = 'female'"
            >
              {{ content.genderSwitch['female'] }}
            </button>
            <button
              class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
              :class="{
                'border border-white border-opacity-15 bg-[#2a3b5f]':
                  gender == 'male',
              }"
              @click="gender = 'male'"
            >
              {{ content.genderSwitch['male'] }}
            </button>
          </div>
          <ModelCard
            v-for="(model, i) in shownPremadeModels"
            :key="model.model.name"
            :position="i"
            :style="i >= 4 ? 'content-visibility: auto' : ''"
            :model-info="model"
          ></ModelCard>
        </div>
      </div>
      <GenerateModelCard
        class="mt-[23px] lg:mt-[51px]"
        v-bind="content.card"
        :models="
          !content.genderSwitch
            ? content.card.models
            : gender === 'female'
              ? content.genderSwitch.cardModelsFemale
              : content.genderSwitch.cardModelsMale
        "
        :cta="content.cta"
        :ctaSm="content.ctaSm"
        :ctaLink="
          !content.genderSwitch
            ? content.ctaLink
            : gender === 'female'
              ? content.genderSwitch.ctaLinkFemale
              : content.genderSwitch.ctaLinkMale
        "
      />
      <FAQCard class="mt-[23px] lg:mt-[51px]" v-bind="content.faq"></FAQCard>
      <InfoCard class="mt-[23px] lg:mt-[51px]" v-bind="content.info"></InfoCard>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>
