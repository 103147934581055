export default {
  route: '/ai-boyfriend',
  type: 'boyfriend',
  meta: {
    title: `${import.meta.env.VITE_BRAND_NAME} | AI Boyfriend App | Chat & Pictures`,
    description:
      'Explore your deepest fantasies with our AI boyfriend chat. Our AI boyfriends are open to anything and are ready to serve you and your needs.',
    breadcrumbName: 'AI Boyfriend',
  },
  content: {
    cta: 'Create AI Boyfriend',
    ctaSm: 'Create Boyfriend',
    ctaLink: '/create-boyfriend',
    banner: {
      title:
        'Create your own <br class="lg:hidden"><span class="text-purple-500">AI Boyfriend</span>',
      description: `Adventure, passion, or just someone to chat with after a long day—your AI boyfriend is here. Design his looks, shape his personality, and watch him come alive.`,
      image: 'banner-bf',
    },
    card: {
      title: `Your Cute AI Boyfriend is Ready For You`,
      description: `Your perfect AI boyfriend is just a click away. Design your dream guy—from his charming smile to his captivating personality—and watch him come to life. He's ready to chat with you, send you pics, and help you explore your wildest dreams.`,
      models: ['Sean', 'Amir', 'Logan', 'Tyler'],
    },
    faq: {
      qa: [
        {
          question: `How does a virtual AI boyfriend work?`,
          answer: `An AI boyfriend is a virtual partner who can make your secret desires and wildest dreams come true. He'll surprise you with his ability to chat, share pics that'll make you blush, and keep you coming back for more.`,
        },
        {
          question: `Can I customize my AI bf's appearance?`,
          answer:
            'Yes, you can! From his chiseled jawline to his mesmerizing eyes, those strong hands holding yours, or that mischievous grin making you weak in the knees—every feature is yours to mold.',
        },
        {
          question: `Can I teach my AI boyfriend new things?`,
          answer: `You bet! Every conversation deepens the bond. Your AI boyfriend has a heart (and a memory!) that grows with every conversation. Spill your secrets, and share your dream - he'll remember everything and do exactly what you want to fulfill your desires.`,
        },
        {
          question: `Can I have multiple AI boyfriends at once?`,
          answer: `Of course! Build your own league of AI boyfriends, each one uniquely crafted to fulfill your every fantasy and desire. From the boy next door to the mysterious bad boy - your options are limitless.`,
        },
        {
          question: `Can he send pictures?`,
          answer: `Yeah, he can! Your AI boyfriend is your virtual dress-up doll, ready to model any look you can dream up. Tell him to dress in a nice outfit and watch the temperature rise.`,
        },
        {
          question: 'Is my privacy protected?',
          answer: `Yes, of course! Whisper your secrets, share your dreams... he's all yours. Our commitment to privacy means your conversations stay between you and your AI boyfriend. We've got your back with the latest security tech, so you can explore your deepest desires and let your fantasies unfold.`,
        },
      ],
    },
    info: {
      title:
        'Welcome to our <span class="text-purple-500">AI Boyfriend</span> App',
      description: `Welcome to HeraHaven, where you can create your dream AI boyfriend to make your fantasies become reality.<br><br>Craft your AI boyfriend from scratch, down to the very last detail. He'll be everything you've ever fantasized about — attentive, charming, and always ready to make your heart flutter.<br><br>You can customize his looks, his personality, everything. He'll learn your inside jokes, your dreams, even those little things that drive you crazy.<br><br>Every conversation, every shared moment will bring you closer. He'll chat with you, share pics that'll make you swoon, and learn all about you—the good, the bad, and the downright adorable.<br><br>Get ready for a flood of personalized pics and those special selfies that'll leave you breathless. He'll keep you guessing, wanting more, and always coming back for that next heart-stopping moment.<br><br>Your perfect match is waiting at HeraHaven.<br><br>Join for free today and start building the online connection you've been needing.`,
    },
  },
}
