<script setup>
import { toRefs, computed } from 'vue'
import ButtonComponent from '../../../components/ButtonComponent.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { getSrcSet } from '@/helpers/srcSetHelper.js'

const props = defineProps({
  title: String,
  description: String,
  image: String,
  cta: String,
  ctaSm: String,
  ctaLink: String,
})

const { title, description, image, cta, ctaSm, ctaLink } = toRefs(props)

const bannerSrc = computed(() => getSrcSet('banners', image.value))
</script>

<template>
  <div
    class="flex items-center pt-[10px] pb-[15px] px-5 lg:px-0 lg:py-16 lg:pl-[52px] rounded-[25px] border border-[#FFFFFF0D] relative"
    style="
      background: radial-gradient(
        123.96% 123.96% at 18.89% 102.14%,
        rgba(76, 12, 81, 0.2) 0%,
        rgba(37, 6, 38, 0) 100%
      );
    "
  >
    <div
      class="lg:w-[40%] 2xl:w-[50%] flex flex-col items-start gap-4 lg:gap-[27px] relative z-10 select-none"
    >
      <h1
        class="text-lg lg:text-[35px] lg:leading-[45px] select-text"
        v-html="title"
      ></h1>
      <p
        class="w-full text-[16px] select-text hidden lg:block"
        v-html="description"
      ></p>
      <ButtonComponent
        :to="ctaLink"
        class="flex items-center gap-[5px] lg:gap-[15px] py-2 px-2 lg:py-[10px] lg:pl-[11px] lg:pr-[19px] text-[14px]"
      >
        <SvgIcon
          sprite="home"
          icon-id="magic-wand"
          class="h-5 w-5 lg:h-6 lg:w-6"
          alt="Magic wand"
        />
        <span class="inline lg:hidden">{{ ctaSm }}</span>
        <span class="hidden lg:inline">{{ cta }}</span>
      </ButtonComponent>
    </div>
    <picture>
      <source
        :srcset="bannerSrc.mobileAvif"
        media="(max-width: 1023px)"
        type="image/avif"
      />
      <source
        :srcset="bannerSrc.mobileWebp"
        media="(max-width: 1023px)"
        type="image/webp"
      />

      <source
        :srcset="bannerSrc.desktopAvif"
        media="(min-width: 1024px)"
        type="image/avif"
      />
      <source
        :srcset="bannerSrc.desktopWebp"
        media="(min-width: 1024px)"
        type="image/webp"
      />

      <img
        :src="bannerSrc.default"
        alt="Banner"
        class="inline absolute top-0 right-0 h-[100%] rounded-tr-[25px] select-none rounded-br-[25px] object-cover"
        loading="eager"
        fetchpriority="high"
        decoding="async"
      />
    </picture>
  </div>
</template>
