import { setUser as sentrySetUser } from '@sentry/vue'
import createMixpanelData from 'common/lib/createMixpanelData'
import mixpanel from 'mixpanel-browser'
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    analytics: state.analytics,
    utm: state.utm,
    userBearerToken: state.userBearerToken,
    discountCode: state.discountCode,
    discountEndDate: state.discountEndDate,
    lastAppPage: state.lastAppPage,
    freeRating: state.freeRating,
    email: state.email,
    password: state.password,
    falseGeneratedImages: state.falseGeneratedImages,
    banned: state.banned,
    warned: state.warned,
    bannableOffenses: state.bannableOffenses,
    warnReason: state.warnReason,
    guestImages: state.guestImages,
    tempGender: state.tempGender,
    freeGeneratedImages: state.freeGeneratedImages,
  }),
})

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    pendingModel: state.pendingModel,
  }),
})

// Create a new store instance.
const store = createStore({
  plugins: [vuexLocal.plugin, vuexSession.plugin],
  state() {
    return {
      country: null,
      countryState: null,
      user: null,
      userBearerToken: null,
      premadeModel: null,
      analytics: {},
      utm: {},
      selectedModel: null,
      hasMore: true,
      messages: [],
      typing: false,
      noBalancePopupOpen: false,
      subscribePopupOpen: false,
      subscribePopupText: '',
      subscribeRedirectPath: '/',
      genericPopupOpen: false,
      genericPopupTitle: '',
      genericPopupContent: '',
      purchaseRedirectPath: '/',
      discountPopupOpen: false,
      discountCode: null,
      discountEndDate: null,
      buyLunaPopupOpen: false,
      buyLunaPurchaseNumber: null,
      loginRegisterPopupOpen: false,
      loginRegisterPopupText: '',
      mobileMenuOpen: false,
      lastAppPage: null,
      routes: {
        previous: null,
        current: null,
      },
      chatSessionId: null,
      freeRating: {},
      generatorPopupOpen: false,
      pendingModel: null,
      paymentError: null,
      email: '',
      password: '',
      freeGeneratedImages: [],
      banned: false,
      warned: false,
      bannableOffenses: 0,
      guestModels: [],
      unlockClickTracked: false,
      unlockClickModelId: null,
      unlockClickMessageId: null,
      guestImages: [],
      freeLimitHit: null,
      tempGender: null,
      companyDetails: {
        corpCountry: '---',
        corpName: '---',
        corpAddress: '---',
        corpNumber: '---',
        siteDescriptor: '---',
      },
      subscribeExpiredPopupOpen: false,
      subscribeExpiredPopupText: '',
    }
  },
  mutations: {
    SET_CHAT_SESSION_ID(state, id) {
      state.chatSessionId = id
    },
    SET_ROUTES(state, { previous, current }) {
      state.routes.previous = previous.path.slice(1)
      state.routes.current = current.path.slice(1)
    },
    SET_PURCHASE_REDIRECT_PATH(state, path) {
      state.purchaseRedirectPath = path
    },
    SET_USER_BEARER_TOKEN(state, token) {
      state.userBearerToken = token
    },
    SET_USER(state, user) {
      state.user = user

      if (user) {
        if (user.models?.length) {
          state.selectedModel = user.models[0].id
        }

        state.freeGeneratedImages = user.freeGeneratedImages

        if (import.meta.env.VITE_TIKTOK_PIXEL_CODE) {
          ttq.identify({
            email: user.email,
          })
        }

        if (window.hj) {
          hj('identify', user.id)
        }

        if (import.meta.env.VITE_MIXPANEL_TOKEN) {
          mixpanel.identify(user.id)
        }

        if (
          import.meta.env.VITE_SENTRY_DSN &&
          import.meta.env.VITE_NODE_ENV == 'production'
        ) {
          sentrySetUser({ email: user.email })
        }
      } else {
        if (import.meta.env.VITE_MIXPANEL_TOKEN) {
          console.log('clear mixpanel')
          mixpanel.reset()
          // mixpanel.cookie.clear()
        }
      }

      if (import.meta.env.VITE_MIXPANEL_TOKEN) {
        let mixpanelData = createMixpanelData(user)

        mixpanel.register(mixpanelData) // Super property
        mixpanel.people.set(mixpanelData) // Profile property
      }
    },
    SET_SELECTED_MODEL(state, selectedModel) {
      state.selectedModel = selectedModel
    },
    SET_PREMADE_MODEL(state, model) {
      state.premadeModel = model
    },
    MAKE_MODEL_FIRST(state, { modelId, message }) {
      if (state.user && state.user.models) {
        // Handle signed-in user interactions
        const modelIndex = state.user.models.findIndex((m) => m.id === modelId)

        if (modelIndex === -1) {
          console.error('Model not found.')
          return
        }

        // Update the model's messages
        state.user.models[modelIndex].messages = [message]

        // Move the model to the beginning of the array if it's not already first
        if (modelIndex !== 0) {
          const model = state.user.models[modelIndex]
          state.user.models.splice(modelIndex, 1)
          state.user.models.unshift(model)
        }
      } else {
        // Handle guest interactions
        const modelIndex = state.guestModels.findIndex((m) => m.id === modelId)

        if (modelIndex === -1) {
          // If model not found, add it to guest models
          state.guestModels.push({ id: modelId, messages: [message] })
          return
        }

        // Update the model's messages
        state.guestModels[modelIndex].messages = [message]

        // Move the model to the beginning of the array if it's not already first
        if (modelIndex !== 0) {
          const model = state.guestModels[modelIndex]
          state.guestModels.splice(modelIndex, 1)
          state.guestModels.unshift(model)
        }
      }
    },
    ADD_MESSAGES(state, messages) {
      state.messages.push(...messages)
      if (state.user && typeof state.user.totalMessageCount === 'number')
        state.user.totalMessageCount += messages.length
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages
    },
    ADD_OLDER_MESSAGES(state, messages) {
      state.messages.unshift(...messages)
    },
    DELETE_RECENT_MESSAGES(state, count) {
      for (let i = 0; i < count; i++) state.messages.pop()
    },
    SET_HAS_MORE(state, val) {
      state.hasMore = val
    },
    INSERT_MESSAGES(state, { messages, index }) {
      state.messages.splice(index + 1, 0, ...messages)
    },
    UPDATE_MESSAGE_DATA(state, { id, image, voice, modelId, createdAt }) {
      const lastMessage = state.messages[state.messages.length - 1]
      if (!lastMessage) throw new Error('Message not found')
      lastMessage.id = id
      lastMessage.cdnImage = image
      lastMessage.cdnVoice = voice
      lastMessage.modelId = modelId
      lastMessage.createdAt = createdAt
    },
    REPLACE_MESSAGE(state, message) {
      const index = state.messages.findIndex((m) => m.id == message.id)
      if (index == -1)
        throw new Error(`Message with id ${message.id} not found on storage`)
      state.messages[index] = message
    },
    ADD_MESSAGE_VOICE(state, { messageId, voice, voiceContent }) {
      const message = state.messages.find((m) => m.id == messageId)
      message.voice = voice
      message.voiceContent = voiceContent
    },
    REPLACE_MODEL(state, { oldModelId, newModel }) {
      state.user.models[
        state.user.models.findIndex((model) => model.id == oldModelId)
      ] = newModel
      if (state.selectedModel == oldModelId) state.selectedModel = newModel.id
    },
    UPDATE_MODEL_PERSONA(state, { modelId, persona, userPersona }) {
      const model = state.user.models.find((m) => m.id == modelId)
      model.persona = persona
      model.userPersona = userPersona
    },
    UPDATE_MODEL_MEMORY(state, { modelId, text }) {
      const model = state.user.models.find((m) => m.id == modelId)
      if (model.summaries.length === 0) return

      model.summaries[0].text = text
    },
    UPDATE_MODEL_PROFILE_PICTURE(state, { modelId, image }) {
      state.user.models.find((m) => m.id == modelId).profilePicture = image
    },
    CHANGE_LUNA(state, val) {
      // Users on trial always have 0 luna
      if (!state.user) return
      if (state.user.subscription) {
        state.user.luna += val
      }
    },
    ADD_MODEL(state, val) {
      state.user.models.unshift(val)
      if (
        typeof state.user.totalCustomModelCount === 'number' &&
        !val.premadeId
      )
        state.user.totalCustomModelCount++
    },
    DELETE_MODEL(state, modelId) {
      const idx = state.user.models.findIndex((m) => m.id == modelId)
      state.user.models.splice(idx, 1)
    },
    SET_ANALYTICS_VARIABLE(state, { key, val }) {
      state.analytics[key] = val
    },
    SET_DISCOUNT_CODE(state, { code, endDate }) {
      state.discountCode = code
      state.discountEndDate = endDate
    },
    SET_DISCOUNT_POPUP(state, { open }) {
      state.discountPopupOpen = open
    },
    SET_SUBSCRIPTION_CANCELLED(state, val) {
      state.user.subscriptionCancelled = val
    },
    SET_SUBSCRIPTION(state, val) {
      state.user.subscription = val
    },
    SET_SUBSCRIPTION_NEXT_PAYMENT_DATE(state, val) {
      state.user.subscriptionNextPaymentDate = val
    },
    SET_NAME(state, val) {
      state.user.name = val
    },
    SET_UTM(state, val) {
      state.utm = val
    },
    CLEAR_UTM(state, val) {
      state.utm = []
    },
    SAVE_MESSAGE(state, { id, saved }) {
      state.messages.find((m) => m.id == id).saved = saved
    },
    ADD_USER_IMAGES(state, images) {
      state.user.images.push(...images)
    },
    SET_TYPING(state, val) {
      state.typing = val
    },
    SET_SUBSCRIBE_POPUP(state, { open, text, freeLimitHit }) {
      state.subscribePopupOpen = open
      if (open && text) {
        state.subscribePopupText = text
      } else if (!open) {
        // The delay exists because there is a transition to closing the popup
        // we want the text to be changed only after the popup is closed
        // so it doesn't flash
        setTimeout(
          () => !state.subscribePopupOpen && (state.subscribePopupText = ''),
          500,
        )
      }
      if (open) {
        state.freeLimitHit = freeLimitHit || false
      }
    },
    SET_NO_BALANCE_POPUP(state, { open }) {
      state.noBalancePopupOpen = open
    },
    // generic popup for errors and similar information relevant to user
    SET_GENERIC_POPUP(state, { open, title, content }) {
      state.genericPopupOpen = open
      state.genericPopupTitle = title
      state.genericPopupContent = content
    },
    SET_BUY_LUNA_POPUP(state, { open, purchaseNumber }) {
      state.buyLunaPopupOpen = open
      state.buyLunaPurchaseNumber = purchaseNumber
    },
    SET_LOGIN_REGISTER_POPUP_OPEN(state, { open, text, freeLimitHit }) {
      state.loginRegisterPopupOpen = open
      if (open && text) {
        state.loginRegisterPopupText = text
      } else if (!open) {
        // The delay exists because there is a transition to closing the popup
        // we want the text to be changed only after the popup is closed
        // so it doesn't flash
        setTimeout(
          () =>
            !state.loginRegisterPopupOpen &&
            (state.loginRegisterPopupText = ''),
          500,
        )
      }
      if (open) {
        state.freeLimitHit = freeLimitHit || false
      }
    },

    SET_FREE_RATING(state, { rating }) {
      state.freeRating = {
        rating: rating,
      }
    },
    SET_MOBILE_MENU_OPEN(state, { open }) {
      state.mobileMenuOpen = open
    },
    SET_GENERATOR_POPUP_OPEN(state, { open }) {
      state.generatorPopupOpen = open
    },
    UPDATE_LAST_APP_PAGE(state) {
      let lastAppPage =
        window.location.pathname.slice(1) + window.location.search

      if (lastAppPage.includes('?discountCode=')) {
        lastAppPage = lastAppPage.split('?discountCode=')[0]
      }

      state.lastAppPage = encodeURIComponent(lastAppPage)
    },
    SET_BUY_LUNA_PURCHASE_NUMBER(state, purchaseNumber) {
      state.buyLunaPurchaseNumber = purchaseNumber
    },
    SET_COUNTRY(state, { country, countryState }) {
      state.country = country
      state.countryState = country === 'US' ? countryState : null
    },
    SET_GUEST_IMAGES(state, images) {
      state.guestImages = images
    },

    // Pending Model Mutations
    SET_PENDING_MODEL(state, newPendingModel) {
      state.pendingModel = newPendingModel
    },
    RESET_PENDING_MODEL(state) {
      state.pendingModel = null
    },

    // Payment Error Mutations
    SET_PAYMENT_ERROR(state, error) {
      state.paymentError = error
    },

    // User Mutations
    SET_EMAIL(state, email) {
      state.email = email
    },
    SET_PASSWORD(state, password) {
      state.password = password
    },
    ADD_FREE_GENERATED_IMAGE(state, imageId) {
      state.freeGeneratedImages.push(imageId)
    },
    SET_FREE_GENERATED_IMAGES(state, imagesArray) {
      state.freeGeneratedImages = imagesArray
    },

    // Warn Mutations
    TOGGLE_BANNED(state, value) {
      state.banned = value
    },
    TOGGLE_WARNED(state, value) {
      state.warned = value
    },
    SET_WARN_REASON(state, value) {
      state.warnReason = value
    },
    SET_BANNABLE_OFFENSES(state, value) {
      state.bannableOffenses = value
    },
    SET_TEMP_GENDER(state, value) {
      state.tempGender = value
    },
    SET_COMPANY_DETAILS(state, value) {
      state.companyDetails = value
    },
    SET_SUBSCRIBE_EXPIRED_POPUP(state, { open, text }) {
      console.log('set subscribe expired popup', open)
      state.subscribeExpiredPopupOpen = open
      state.subscribeExpiredPopupText = text || ''
    },
  },
  getters: {
    model: (state) =>
      state.user
        ? state.user?.models.find((m) => m.id == state.selectedModel)
        : state.premadeModel,
    firstModel: (state) =>
      state.user?.models.length ? state.user?.models[0] : null,
    models: (state) => state.user?.models,
    deterModel: (state) => state.user?.models.find((model) => model.deterImage),
    showHints: (state) =>
      !state.user ||
      (state.user && state.user.models && state.user.models.length <= 1),
  },
})

export default store
