<script setup>
const props = defineProps({
  iconId: {
    type: String,
    required: true,
  },
  sprite: {
    type: String,
    default: 'main',
  },
  viewBox: {
    type: String,
    default: null,
  },
  background: {
    type: String,
    default: null,
  },
})

// This is created as a new Set on main.js
window.spritesToBeLoaded.add(props.sprite)
</script>

<template>
  <svg :viewBox="viewBox" :preserveAspectRatio="background">
    <use :href="`#${iconId}`"></use>
  </svg>
</template>
