<script setup>
import { computed, toRefs } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { generateSrcSet, getSrcSet } from '@/helpers/srcSetHelper'

const props = defineProps({
  modelInfo: Object,
  position: Number,
  version18: {
    type: Boolean,
    default: false,
  },
})

const { modelInfo, position } = toRefs(props)

const calculatedModelRoute = computed(() => {
  if (props.modelInfo.model.style === 'anime') {
    return `/ai-hentai/${props.modelInfo.model.name.toLowerCase()}`
  }

  if (props.modelInfo.model.gender === 'female') {
    return `/ai-girlfriend/${props.modelInfo.model.name.toLowerCase()}`
  }
  return `/ai-boyfriend/${props.modelInfo.model.name.toLowerCase()}`
})

const modelSrc = computed(() => getSrcSet('models', modelInfo.value.model.name))
</script>

<template>
  <router-link :to="calculatedModelRoute">
    <div
      class="relative rounded-t-[15px] lg:rounded-t-[20px] overflow-hidden max-w-[75vw] sm:max-w-unset"
    >
      <div
        class="shadow-[inset_0_-40px_40px_rgba(0,0,0,0.66)] absolute top-0 left-0 w-full h-full"
      ></div>
      <picture>
        <source
          type="image/avif"
          :srcset="modelSrc.mobileAvif"
          media="(max-width: 450px)"
        />
        <source
          type="image/webp"
          :srcset="modelSrc.mobileWebp"
          media="(max-width: 450px)"
        />
        <source
          type="image/avif"
          :srcset="modelSrc.desktopAvif"
          media="(min-width: 451px)"
        />
        <source
          type="image/webp"
          :srcset="modelSrc.desktopWebp"
          media="(min-width: 451px)"
        />
        <img
          class="w-full aspect-[832/1216] no-image-select"
          :src="modelSrc.default"
          :alt="modelInfo.model.name"
          draggable="false"
          :loading="position < 4 ? 'eager' : 'lazy'"
          decoding="async"
        />
      </picture>
      <div
        class="absolute bottom-[5px] left-[10px] lg:bottom-[10px] w-full flex flex-row items-baseline gap-[5px] text-lg lg:text-[25px]"
      >
        {{ modelInfo.model.name }}
        <span class="text-base text-[#B1B5DB]">{{ modelInfo.model.age }}</span>
      </div>
    </div>
    <div
      class="flex items-center gap-[10px] pl-[10px] pr-[7px] sm:px-[14px] h-[55px] text-[13px] lg:text-[14px] border border-white border-opacity-15 rounded-b-[15px] lg:rounded-b-[20px]"
      style="
        background: rgb(14, 18, 42);
        box-shadow:
          rgb(30, 26, 61) 0px 0px 0px 1px,
          rgb(19, 20, 46) 0px 0px 10px 2px inset;
      "
    >
      <SvgIcon
        sprite="home"
        icon-id="star"
        class="h-[15px] w-[15px] flex-shrink-0"
      />
      {{ modelInfo.phrase }}
    </div>
  </router-link>
</template>

<style scoped></style>
