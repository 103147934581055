export default {
  route: '/ai-hentai',
  type: 'anime',
  meta: {
    title: `${import.meta.env.VITE_BRAND_NAME} | AI Hentai App | Chat & Generate Pictures`,
    description: `Explore your deepest fantasies with our AI hentai chat. Generate your own AI hentai character that's open to anything and ready to serve you and your needs.`,
    breadcrumbName: 'AI Hentai',
  },
  content: {
    cta: 'Create AI Character',
    ctaSm: 'Create Character',
    banner: {
      title:
        'Generate your own <br class="lg:hidden"><span class="text-purple-500">AI Hentai</span>',
      description: `Say hi to your new AI hentai character! Generate their looks and personality just the way you want, and with one click, watch them come to life.`,
    },
    genderSwitch: {
      female: 'Waifus',
      male: 'Yaoi',
      bannerImageFemale: 'banner-gf-anime',
      bannerImageMale: 'banner-bf-anime',
      ctaLinkFemale: '/create-model?style=anime&gender=female',
      ctaLinkMale: 'create-model?style=anime&gender=male',
      cardModelsFemale: ['Aria', 'Akari', 'Ami', 'Ayumi'],
      cardModelsMale: ['Ash', 'Daichi', 'Kai', 'Elric'],
    },
    card: {
      title: `Your Fantasies Come True With An AI Hentai Character`,
      description: `Will they be a tsundere with a hidden soft side or a bubbly/genki always ready for an adventure? Just one click to figure out! Imagine late-night chats filled with flirty stories, <a href="/sexy-ai" class="underline">sexy ai</a> pics that'll make you sweat`,
    },
    faq: {
      qa: [
        {
          question: `How does a virtual AI hentai work?`,
          answer: `An AI hentai is your virtual waifu or yaoi who can make your deepest fantasies come true. From <a href="/spicy-ai" class="underline">spicy ai</a> chats to sweaty selfies, they're here to fulfill your deepest desires. The best part is they'll adapt to your style and grow with you, as long as you keep talking to them.`,
        },
        {
          question: `Can I customize my AI hentai's appearance?`,
          answer: `Yes, you can! Customize their every detail, from those captivating eyes to that flowing hair. Whether you're into the classic schoolgirl/schoolboy look or something a little more daring, your AI hentai will be everything you've ever dreamed of`,
        },
        {
          question: `Can I teach my AI hentai new things?`,
          answer: `The more you chat, the more they'll understand your unique quirks and preferences. And if you want something new from them, just ask! They're all ears, and they're quick learners too.`,
        },
        {
          question: `Can I generate multiple AI hentais?`,
          answer: `Your desires, your rules. Craft an entire harem of AI waifus or yaoi, each one tailored to your specific tastes. Whether you crave tsundere antics, dandere devotion, or even yandere intensity, the power is in your hands.`,
        },
        {
          question: 'Can my AI hentai send spicy pictures?',
          answer: `Of course! Dress them up, show them off! Your AI hentai is ready for a cosplay session, and you're the photographer. Snap some pics, and let your imaginations run wild. No request is too spicy for your AI hentai.`,
        },
        {
          question: 'Is my chat history kept private?',
          answer:
            'Your secrets are safe with us. We use top-notch encryption to keep your conversations private, so you can explore your deepest desires with your AI hentai and let your imagination run wild.',
        },
      ],
    },
    info: {
      title:
        'Generate Amazing <span class="text-purple-500">AI Hentai</span> Art',
      description: `Welcome to HeraHaven, an AI hentai chat where your dreams become reality.<br><br>Craving a shy and endearing kouhai or a confident and alluring senpai? At HeraHaven, your deepest desires will be fulfilled.<br><br>Generating AI hentai at HeraHaven is like playing dress-up, but way more fun.<br><br>Choose the characters' looks, their personality, and their little quirks, then watch them come to life with just a click. They're as sweet as <a href="/candy-ai" class="underline">candy ai</a>.<br><br>They'll send you steamy selfies and connect with you like they've stepped right out of your favorite anime. Chat with them casually, or go through a steamy <a href="/ai-sexting" class="underline">AI sext</a> experience.<br><br>Your dream anime waifu or yaoi are just a click away. Don't keep them waiting any longer!<br><br>Join HeraHaven for free today and experience the magic of AI generated hentai.`,
    },
  },
}
