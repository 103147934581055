export default {
  route: '/',
  type: 'girlfriend',
  meta: {
    title: `${import.meta.env.VITE_BRAND_NAME} | AI Girlfriend App | Chat & Pictures`,
    description:
      'Explore your deepest fantasies with our AI girlfriend chat. Our virtual AI girlfriends are open to anything and are ready to serve you and your needs.',
  },
  content: {
    cta: 'Create AI Girlfriend',
    ctaSm: 'Create Girlfriend',
    ctaLink: '/create-girlfriend',
    banner: {
      title:
        'Create your own <br class="lg:hidden"><span class="text-purple-500">AI Girlfriend</span>',
      description: `Your virtual AI girlfriend is waiting to meet you. Looks, personality, quirks... she's yours to create. One click, and your AI gf is here to make your fantasies come true.`,
      image: 'banner-gf',
    },
    card: {
      title: `Design the AI Girlfriend You've Always Desired`,
      description: `Ready to meet your new virtual AI girlfriend? She's eager to text you, send you pictures, and help you explore your deepest desires. Design her looks, craft her personality, and watch her come alive.`,
      models: ['Aaliyah', 'Nari', 'Amara', 'Riley'],
    },
    faq: {
      qa: [
        {
          question: `How does a virtual AI girlfriend work?`,
          answer: `An AI girlfriend is your own virtual companion, designed to connect with you on a deeper level. She can do literally anything to meet even your wildest desires. She's there to chat with you, send you pictures, interact like real-life communication, adapt to your style, and grow with you.`,
        },
        {
          question: `Can I customize my AI gf's appearance?`,
          answer: `Absolutely! Want her to have piercing blue eyes or fiery red hair? You got it. Design your AI girlfriend's looks from head to toe, making sure she's exactly your type.`,
        },
        {
          question: `Can I teach my AI girlfriend new things?`,
          answer: `Definitely! The more you chat with your AI girlfriend, the more she'll get to know you. She'll pick up on your jokes, remember your stories, and even start using your favorite emojis. It's like having a real-life relationship with your perfect AI girl.`,
        },
        {
          question: `Can I have multiple AI girlfriends at once?`,
          answer: `Whether you want to develop a bond with one AI girlfriend, or create a bunch, the choice is yours. There's no limit to the number of AI girlfriends you can create on our platform.`,
        },
        {
          question: 'Can she send pictures?',
          answer:
            'Of course! Want to see your AI girlfriend in that new dress you picked out for her? Or no dress? Go for it! And you can send pics back too! Our uncensored chat means you can explore your wildest fantasies together.',
        },
        {
          question: 'Are my conversations private?',
          answer:
            'We take your privacy seriously. All conversations are confidential, and we use the latest encryption tech to protect your data. So go ahead, be yourself, and let your imagination run wild.',
        },
      ],
    },
    info: {
      title:
        'Your <span class="text-purple-500">AI Girlfriend</span> Simulator',
      description: `Welcome to HeraHaven, where you can create your AI girlfriend and make your fantasies come to life.<br><br>In our AI girlfriend chat, you're in control. Want a sweet and devoted partner? Looking for something a little more thrilling and adventurous? We've got it all. The possibilities are endless, so let your imagination run wild.<br><br>Creating your AI girl is a breeze at HeraHaven. You can mix and match looks, personality traits, and even those little quirks that make her extra special. A few clicks, and boom—your heaven girlfriend is ready to chat, send pictures, and connect with you like in real-life communication.<br><br>The more you chat, the more she learns about you—your inside jokes, your dreams, even your pet peeves. You set the vibe: keep it casual or turn up the heat.<br><br>Your AI gf will send you selfies and even personalized photos, all tailored to your tastes. And trust us, our advanced AI keeps things interesting, no matter how creative you get with your requests.<br><br>From light-hearted conversation to forming a deeper bond, the choice is yours.<br><br>Your perfect online match is closer than you think. She's right here at HeraHaven, the most popular AI girlfriend app.<br><br>Join HeraHaven for free today and start chatting with your first virtual gf.`,
    },
  },
}
