const generateSrcSet = (folder, name, format, size = '', isXs = false) => {
  if (isXs) {
    return `/${folder}/${format}/${name}-xs.${format}`
  }
  return `/${folder}/${format}/${name}${size ? `-${size}` : ''}.${format}`
}

const getSrcSet = (folder, name, isXs = false) => {
  return {
    desktopAvif: `${generateSrcSet(folder, name, 'avif', 'lg-x1', isXs)} 1x, ${generateSrcSet(folder, name, 'avif', 'lg-x2', isXs)} 2x`,
    desktopWebp: `${generateSrcSet(folder, name, 'webp', 'lg-x1', isXs)} 1x, ${generateSrcSet(folder, name, 'webp', 'lg-x2', isXs)} 2x`,
    mobileAvif: `${generateSrcSet(folder, name, 'avif', 'sm-x1', isXs)} 1x, ${generateSrcSet(folder, name, 'avif', 'sm-x2', isXs)} 2x, ${generateSrcSet(folder, name, 'avif', 'sm-x3', isXs)} 3x`,
    mobileWebp: `${generateSrcSet(folder, name, 'webp', 'sm-x1', isXs)} 1x, ${generateSrcSet(folder, name, 'webp', 'sm-x2', isXs)} 2x, ${generateSrcSet(folder, name, 'webp', 'sm-x3', isXs)} 3x`,
    default: generateSrcSet(folder, name, 'webp', 'lg-x1', isXs),
  }
}

export { getSrcSet, generateSrcSet }
