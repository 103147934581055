<script setup>
import { reactive, computed, toRefs } from 'vue'
import { useMixpanel } from '@/composables/mixpanel'
import SvgIcon from '@/components/SvgIcon.vue'

const mixpanel = useMixpanel()

const props = defineProps({
  qa: Array,
})

const { qa } = toRefs(props)

const isOpen = reactive({})

function toggleFaq(faq) {
  if (!isOpen[faq.question])
    mixpanel.track('explore_faq_click', { selection: faq.question })

  isOpen[faq.question] = !isOpen[faq.question]
}
</script>

<template>
  <div style="content-visibility: auto">
    <h2 class="text-[25px] pb-[20px]">Frequently Asked Questions</h2>
    <div
      class="lg:border border-white border-opacity-5 lg:p-[15px] lg:bg-[#0A0D1E] rounded-[15px] flex flex-col gap-[15px] lg:gap-5"
    >
      <div
        v-for="faq in qa"
        :key="faq.question"
        class="bg-[#111026] px-[18px] lg:px-[28px] rounded-[12px] border border-[#1C1B38]"
      >
        <div
          :id="faq.question"
          class="flex gap-8 items-center py-[15px] lg:py-[28px] cursor-pointer w-full"
          @click="toggleFaq(faq)"
        >
          <h3 class="text-white w-full">
            {{ faq.question }}
          </h3>
          <SvgIcon
            v-if="!isOpen[faq.question]"
            sprite="home"
            icon-id="plus-circle"
            class="h-[26px] w-[26px]"
          />
          <SvgIcon
            sprite="home"
            icon-id="minus"
            v-else
            class="h-[26px] w-[26px]"
          />
        </div>
        <p
          class="pb-4"
          :class="{ hidden: !isOpen[faq.question] }"
          v-html="faq.answer"
        ></p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
